<div>
    <slot />
</div>

<style>
    div {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 10px;
    }

    div > :global(*) {
        flex-grow: 1;
    }

    @media screen and (max-width: 580px) {
        div {
            flex-direction: column;
        }
    }
</style>